.App {
}

.MainContent {
  margin: 20px;
}

.Header {
  padding: 20px;
  background-color: #cacaff;
}
